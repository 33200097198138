import axios from "axios";
import auth from "./auth";
import router from "@/router";

export function httpInterceptor() {
  axios.interceptors.request.use(async (request) => {
    // TODO: read from env variable
    request.baseURL = "https://readerbench.com/api/v2";
    request.headers = request.headers ?? {};

    if (request.data) {
      if (request.data.grant_type && request.data.grant_type == "refresh_token") {
        return request;
      }

      if (request.data.grant_type && request.data.grant_type == "password") {
        return request;
      }
    }

    const token = localStorage.getItem("token");
    request.headers.Authorization = `Bearer ${token}`;

    const now_timestamp = Math.floor(Date.now() / 1000);
    const token_expiration_date_timestamp = Number(
      localStorage.getItem("token_valid_until_timestamp")
    );

    // Check if token it is close to expiration (within 1 hour)
    if (token_expiration_date_timestamp - now_timestamp <= 3600) {
      try {
        await new Promise<void>((resolve, reject) => {
          auth.refresh_access_token(
            (response) => {
              // Set the new access token
              request.headers.Authorization = `Bearer ${response.data.access_token}`;

              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        });
      } catch (error) {
        auth.clearLocalStorage();

        // Redirect to login page
        router.push({ path: "/login", hash: "#session_expired" });

        return Promise.reject("Your session has expired. Please log in again to continue.");
      }
    }

    return request;
  });

  //TODO: Could cause problems if 401 status is not emitted due to using an invalid token -> to check
  // axios.interceptors.response.use(
  //   (response) => {
  //     return response;
  //   },
  //   (error) => {
  //     if (error.response) {
  //       const status = error.response.status;

  //       if (status === 401) {
  //         // Handle error due to invalid token usage
  //         auth.clearLocalStorage();

  //         // Redirect to login page
  //         router.push({ path: "/login", hash: "#session_expired" });

  //         return Promise.reject("Your session has expired. Please log in again to continue.");
  //       }
  //     }

  //     return Promise.reject(error);
  //   }
  // );
}
